import { useEffect, useState } from 'react';
import apiService from 'services/BackendApi/BackendApi';

export function useWatchNextPage(props) {
    const { quantity,sortby,cat1,cat2,cat3,cat4,cat5,alias } = props;
    const [pageContent, setPageContent] = useState({
		isLoading: false,
		isError: false,
		payload: null,
		error: null,
	});

	useEffect(() => {
		setPageContent((prevPageContent) => ({
			...prevPageContent,
			isLoading: true,
		}));
		apiService
			.GetWatchNextOptions({
				params:{ 
                    respp: quantity,
                    contenttype:'rich_media_archive',
					sortby:sortby,
					/* cat1:cat1,
					cat2:cat2,
					cat3:cat3,
					cat4:cat4,
					cat5:cat5 */
                },
			})
			.then((response) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: false,
					payload: {
						...response.data,
					},
				}));
			})
			.catch((error) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: true,
					error: error?.message,
				}));
			})
			.finally(() => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isLoading: false,
				}));
			});
	}, [quantity,alias]);

	return pageContent;

}

export function useWatchNextRandomVideo() {
    const [pageContent, setPageContent] = useState({
		isLoading: false,
		isError: false,
		payload: null,
		error: null,
	});

	useEffect(() => {
		setPageContent((prevPageContent) => ({
			...prevPageContent,
			isLoading: true,
		}));
		apiService
			.GetRandomVideo()
			.then((response) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: false,
					payload: {
						...response.data,
					},
				}));
			})
			.catch((error) => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isError: true,
					error: error?.message,
				}));
			})
			.finally(() => {
				setPageContent((prevPageContent) => ({
					...prevPageContent,
					isLoading: false,
				}));
			});
	}, []);

	return pageContent;

}


