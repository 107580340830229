import BasicLayout from 'common/layouts/BasicLayout/BasicLayout';
import SideBarLayout from 'common/layouts/SideBarLayout/SideBarLayout';
import { importComponent } from 'common/loaders/importComponent';
//import GlifosMetadataLoading from 'modules/glifos-rich-media/components/GLifosMetadata/GlifosMetadataLoading/GlifosMetadataLoading';
import NewsLoading from 'modules/website/components/News/NewsLoading/NewsLoading';
import NewsnEvents from 'modules/website/components/NewsnEvents/NewsnEvents';
import BrowseByCategory from 'modules/website/components/SideBars/BrowseByCategory/BrowseByCategory';
import BrowseByGrade from 'modules/website/components/SideBars/BrowseByGrade/BrowseByGrade';
import BrowseByTopic from 'modules/website/components/SideBars/BrowseByTopic/BrowseByTopic';
import BrowseByYear from 'modules/website/components/SideBars/BrowseByYear/BrowseByYear';
import OtherReources from 'modules/website/components/SideBars/OtherReources/OtherReources';
import SideBar from 'modules/website/components/SideBars/SideBar/SideBar';
import WatchNext from 'modules/website/components/SideBars/WatchNext/WatchNext';
import { useNodePage } from 'modules/website/hooks/useNodePage';
import React, { Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ComponentMap } from 'routes/ComponentMap';

export default function NodeView() {
	/* const [ViewLoad, setViewLoad] = useState(null); */
	const [leftBar] = useState(null);
	const [rightBar, setRightBar] = useState(null);
	/* const [title, setTitle] = useState(null);
    const [breadC, setBreadC] = useState(null);
    const [description, setDescription] = useState(null); */
	const [component, setComponent] = useState(null);
	//const nid = location.pathname.replace('/node/', '');
	const { nid } = useParams();
	const { isLoading, isError, payload, error } = useNodePage({
		nid: nid,
	});

	/* useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []); */

	useEffect(() => {
		if (!isLoading && payload) {
			const type = payload.type[0].target_id;
			//console.log(type);
			switch (type) {
				case 'news':
					//get from ComponentMap where type is the same
					setComponent(
						ComponentMap.find(
							(componente) => componente.type === type
						)
					);
					setRightBar(
						<SideBar>
							<NewsnEvents
								quantity={3}
								title='More News & Events'
							/>
							<BrowseByYear />
							<BrowseByCategory />
						</SideBar>
					);
					break;
				/* case 'rich_media_archive':
					//get from ComponentMap where type is the same
					setComponent(
						ComponentMap.find(
							(componente) => componente.type === type
						)
					);
					setRightBar(
						<SideBar>
							<WatchNext quantity={9} alias={nid} />
						</SideBar>
					);

					break; */
				case 'information':
					setRightBar(
						<SideBar>
							<WatchNext btnMore quantity={3} />
							<NewsnEvents btnMore={true} />
						</SideBar>
					);
					setComponent(
						ComponentMap.find(
							(componente) => componente.type === type
						)
					);
					break;
				case 'content_lesson_plan':
					setComponent(
						ComponentMap.find(
							(componente) => componente.type === type
						)
					);
					setRightBar(
						<SideBar>
							<BrowseByTopic />
							<BrowseByGrade />
							<OtherReources />
						</SideBar>
					);
					break;
				case 'rich_media_archive':
					//get from ComponentMap where type is the same
					setComponent(
						ComponentMap.find(
							(componente) => componente.type === type
						)
					);
					setRightBar(
						<SideBar>
							<WatchNext
								quantity={9}
								alias={payload.path[0].alias}
							/>
						</SideBar>
					);

					break;
				default:
					// Manejo de tipo desconocido o sin asignar
					break;
			}
		}
		// eslint-disable-next-line
	}, [payload]);

	const ViewComponent = component ? importComponent(component.c) : null;

	return component && component.l === 'right' ? (
		<SideBarLayout leftBar={leftBar} rightBar={rightBar}>
			<Suspense fallback={<NewsLoading />}>
				<ViewComponent
					payload={payload}
					isLoading={isLoading}
					isError={isError}
					error={error}
				/>
			</Suspense>
		</SideBarLayout>
	) : component && component.l === 'central' ? (
		<BasicLayout>
			<Suspense fallback={<div>Loading...</div>}>
				{' '}
				<ViewComponent />
			</Suspense>
		</BasicLayout>
	) : (
		<></>
	);
}
